<template>
  <div
    class="alert"
    :class="[`alert-${element.color}`, element.customClasses]"
    role="alert"
    :style="{
      background: String.isNullOrWhiteSpace(element.linearGradient)
        ? ''
        : element.linearGradient + '!important',
    }"
  >
    <h4
      v-if="element.title !== ''"
      class="alert-heading"
      :style="{
        color: String.isNullOrWhiteSpace(element.fontColor)
          ? ''
          : element.fontColor + '!important',
      }"
    >
      {{ element.title }}
    </h4>
    <div class="d-flex align-items-center">
      <i
        v-if="element.icon !== ''"
        :style="{
          color: String.isNullOrWhiteSpace(element.fontColor)
            ? ''
            : element.fontColor + '!important',
        }"
        :class="`me-1 bi bi-${element.icon} bi-xxlg`"
      />
      <div
        :style="{
          color: String.isNullOrWhiteSpace(element.fontColor)
            ? ''
            : element.fontColor + '!important',
        }"
        v-html="getMessage(element)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ["element"],
  name: "AlertItem",
  methods: {
    getMessage(item) {
      if (this.$store.state.isMultiLanguage) {
        var localizationValue =
          this.$root.getLocalizationValueForPageDesigner(item);
        if (localizationValue) {
          return localizationValue.value;
        } else {
          return item.message;
        }
      } else {
        return item.message;
      }
    },
  },
};
</script>

<style scoped>
.alert {
  margin-bottom: 0.5rem !important;
}
</style>
